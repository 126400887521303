'use client';
import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import { CardAttributes } from '@/features/carousel/model';
import { TGameData } from '@/root/app/[locale]/games/[slug]/model';
import React, { useContext } from 'react';
import { UIKIT_CONSTS } from '../UiKit/UiKitConnector';
import { UiKitRenderStatic } from '../UiKit/UiKitRender';
import Link from 'next/link';
import { LocalStorageService } from '@/shared/utils/local-storage';
import { useDeviceDetector } from '@/shared/utils/userAgentContext';
import { renderMarkup } from '@/uikit/components/ark-ui-block-card-standard/_index';
import { AnalyticsContext } from '@/components/Layout';
import { ETabIndexesOrderingLevels } from '@/shared/app/types';
import { getCookie } from '@/shared/utils/utils';
interface RecentlyPlayedProps {
    arenaData: any;
}

const RecentlyPlayed = ({ arenaData }: RecentlyPlayedProps) => {
    const { isMobile } = useDeviceDetector();
    const recentlyGame = LocalStorageService.getItem('recentlyPlayed');
    const recentlyPlayedGame: TGameData = recentlyGame ? JSON.parse(recentlyGame) : ({} as TGameData);
    const { AITracks } = useContext(AnalyticsContext);
    const getLocal = arenaData?.localeList?.list?.map((l: any) => l.code)?.[0] ?? 'en';
    const locale = getLocal ? `/${getLocal}` : '';

    //validate object
    if (!recentlyGame) {
        return null;
    }

    const recentlyPlayedLinkHref: string = recentlyPlayedGame ? `${locale}/games/${recentlyPlayedGame?.slug}` : '';
    const recentlyPlayedCardAttributesOverride = arenaData?.layout?.styleOverride?.standardCard || null;

    const recentlyPlayedCardTitle: string = 'Recently played';
    const recentlyPlayedCardAttributes: CardAttributes = {
        onMobile: isMobile(),
        size: 'medium',
        action_bar: 'true',
        thumbnail_mode: 'false',
        title: recentlyPlayedCardTitle,
        background_color: 'transparent',
        background_image_desktop: recentlyPlayedGame?.meta?.thumbs?.graphic_288x192 || '',
        background_image_mobile: recentlyPlayedGame?.meta?.thumbs?.graphic_288x192 || '',
        badge: { label: '', backgroundColor: '', labelColor: '' },
        link_label: '',
        game_category: recentlyPlayedGame?.meta?.categories?.[0] || '',
        game_name: recentlyPlayedGame?.name || '',
        game_ext_id: recentlyPlayedGame?.id || 0,
        game_description: recentlyPlayedGame?.meta?.description || '',
        game_slug: recentlyPlayedGame?.slug || '',
        description: '',
        shortDescription: recentlyPlayedGame?.meta?.details || '',
        override_color_title: recentlyPlayedCardAttributesOverride?.title || '',
        override_hover_block_toggled: recentlyPlayedCardAttributesOverride?.hoverBlock?.type,
        override_hover_block_color_bcg: recentlyPlayedCardAttributesOverride?.hoverBlock?.background || '',
        override_hover_block_color_text: recentlyPlayedCardAttributesOverride?.hoverBlock?.color || '',
        override_hover_block_button_text: recentlyPlayedCardAttributesOverride?.hoverBlock?.buttonText || 'Play',
        override_hover_block_button_text_color:
            recentlyPlayedCardAttributesOverride?.hoverBlock?.buttonTextColor || '#000',
        override_hover_block_button_background_color:
            recentlyPlayedCardAttributesOverride?.hoverBlock?.buttonBackground || '#FFF',
        override_hover_block_button_border_color:
            recentlyPlayedCardAttributesOverride?.hoverBlock?.buttonBorderColor || '#000',
    };

    const recentlyPlayedHandleClick = () => {
        AITracks.genericTrack({
            eventName: 'gameCard',
            isNonInteraction: false,
            eventAction: AnalyticsEventAction.CLICK,
            customDimensions: {
                cardType: 'StandardCard',
                cardTitle: recentlyPlayedCardTitle,
                cardImageURL: recentlyPlayedGame?.meta?.thumbs?.graphic_288x192 || '',
                // badge_text: !isMinimalistic ? badgeText : null,
            },
        });
    };

    return (
        <Link
            key={recentlyPlayedLinkHref}
            href={recentlyPlayedLinkHref}
            className={UIKIT_CONSTS.nextjsLinkWrapperClassname + ' promo-card_recently_link'}
            // target={card.link?.target ?? '_self'}
            onClick={recentlyPlayedHandleClick}
            aria-label="Clickable card"
            prefetch={false}
            tabIndex={ETabIndexesOrderingLevels.AUTO}
        >
            <UiKitRenderStatic
                innerHTML={renderMarkup(recentlyPlayedCardAttributes)}
                tagName={'ark-ui-block-card-standard'}
            />
        </Link>
    );
};
export default RecentlyPlayed;
